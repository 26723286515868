import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

import Layout from "../components/layout";
import Seo from "../components/seo";

// Patterns
import PatternFirst from "../images/pattern_01.png";

const BlogPostTemplate = ({ data: { post, header, forms, footer, socials, copyright, user, cookies } }) => {
  const featuredImage = {
    data: post.articlesContent?.articleImg?.localFile?.childImageSharp
      ?.gatsbyImageData,
    alt: post.articlesContent?.articleImg?.altText || ``,
  };

  const locale = post.locale.locale;
  let formContent;

  for (let i = 0; i < forms.nodes.length; i++) {
    if (forms.nodes[i].locale.locale === locale) {
      formContent = forms.nodes[i];
    }
  }

  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={formContent}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={post.locale.locale}
      uri={post.uri}
    >
      <Seo title={post.seo.title} description={post.seo.metaDesc} />
      <header className="head">
        <nav className="breadcrumb" aria-label="Breadcrumb">
          {locale === "ru" && (
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to="/ru/"
                >Главная</Link>
              </li>
              <li className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to="/ru/news/"
                >Новости</Link>
              </li>
              <li className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to={post.seo.breadcrumbs[2].url}
                  dangerouslySetInnerHTML={{
                    __html: post.seo.breadcrumbs[2].text,
                  }}
                ></Link>
              </li>
            </ol>
          )}
          {locale === "uk" && (
            <ol className="breadcrumb__list">
              {post.seo.breadcrumbs.map((value, i) => (
                <li key={i} className="breadcrumb__item">
                  <Link
                    activeClassName="breadcrumb__link__active"
                    className="breadcrumb__link"
                    to={value.url}
                    dangerouslySetInnerHTML={{
                      __html: value.text,
                    }}
                  ></Link>
                </li>
              ))}
            </ol>
          )}
        </nav>
        <h1 itemProp="headline">{parse(post.title)}</h1>
        <div className="date">{post.date}</div>
        <div className="pattern">
          <img src={PatternFirst} alt="Pattern" />
        </div>
      </header>
      <div className="mainImage">
        {featuredImage?.data && (
          <GatsbyImage image={featuredImage.data} alt={featuredImage.alt} />
        )}
      </div>
      {!!post.articlesContent.articleContent && (
        <div
          itemProp="articleBody"
          className="textContent"
          dangerouslySetInnerHTML={{
            __html: post.articlesContent.articleContent,
          }}
        ></div>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query Articles($slug: String!, $locale: String!) {
    post: wpPost(slug: {eq: $slug}, locale: {locale: {eq: $locale}}) {
      articlesContent {
        articleImg {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
            }
          }
          altText
        }
        articleContent
      }
      slug
      title
      locale {
        locale
      }
      seo {
        title
        metaDesc
        breadcrumbs {
          text
          url
        }
      }
      date(formatString: "DD.MM.YYYY")
      content
      uri
    }
    copyright: allWpRepeatableBlocks(
      filter: {slug: {eq: "copyrights"}}
    ) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(filter: {slug: {eq: "ugoda-korystuvacha"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(filter: {slug: {eq: "polityka-cookies"}}) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { slug: { eq: "formy" } }
    ) {
      nodes {
        slug
        title
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          fieldGroupName
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
        locale {
          locale
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
